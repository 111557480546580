"use client";
import { swrConfigObject } from "@src/constants/swr";
import { SWRConfig } from "swr";

export const SWRProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <SWRConfig
      value={{
        ...swrConfigObject,
      }}
    >
      {children}
    </SWRConfig>
  );
};
