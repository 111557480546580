// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import crypto from "crypto";

const secretKey = process.env.CRYPTO_SECRET_KEY;

const ALGORITHM = "aes-128-ctr";
const IV_BYTES = 8;

export function encrypt(id: string) {
  try {
    const iv = Buffer.from(crypto.randomBytes(IV_BYTES));
    const ivstring = iv.toString("hex");

    const cipher = crypto.createCipheriv(ALGORITHM, secretKey, ivstring);
    const crypted = cipher.update(id, "utf8", "hex");

    return ivstring + crypted + cipher.final("hex");
  } catch (error) {
    console.log(error);
  }
}

export function decrypt(key: string) {
  try {
    const ivstring = key.slice(0, IV_BYTES * 2);

    const data = key.slice(IV_BYTES * 2);
    const decipher = crypto.createDecipheriv(ALGORITHM, secretKey, ivstring);
    const dec = decipher.update(data, "hex", "utf8");

    return dec + decipher.final("utf8");
  } catch (error) {
    console.log(error);
  }
}
