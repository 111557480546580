"use client";
import { Analytics } from "@vercel/analytics/react";
import { SnackbarProvider } from "notistack";

export const DefaultProviders = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <>
      <SnackbarProvider>
        <Analytics />
        {children}
      </SnackbarProvider>
    </>
  );
};
