import { DEFAULT_NETWORK_ERROR_MESSAGE } from "@/src/constants";
import axios, { AxiosRequestConfig } from "axios";

export const fetcher = async (
  url: string,
  options: AxiosRequestConfig = {},
  fullURL: boolean = false,
) => {
  let res;

  try {
    res = await axios(
      fullURL ? url : process.env.NEXT_PUBLIC_API_BASE_URL + url,
      {
        method: "GET",
        ...options,
        headers: {
          ...options?.headers,
          "Content-Type": "application/json",
        },
      },
    );

    if (res.status < 200 || res.status >= 300) {
      throw new Error(res.statusText || DEFAULT_NETWORK_ERROR_MESSAGE);
    }

    return res.data;
  } catch (error: any) {
    throw {
      message: error?.response?.data?.detail || DEFAULT_NETWORK_ERROR_MESSAGE,
      ...error,
    };
  }
};
