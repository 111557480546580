import { ReactNode } from "react";
import { Fade } from "@mui/material";
import { fetcher } from "@/src/utils";
import { OptionsObject, SnackbarProviderProps } from "notistack";
import { SWRConfiguration } from "swr";

interface snackbarConfigProps extends Partial<SnackbarProviderProps> {
  children?: ReactNode;
}

export const snackbarConfig: snackbarConfigProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  autoHideDuration: 3000,
  TransitionComponent: Fade,
};

export const snackbarErrorConfig: OptionsObject = {
  ...snackbarConfig,
  variant: "error",
};

export const snackbarSuccessConfig: OptionsObject = {
  ...snackbarConfig,
  variant: "success",
};

export const snackbarWarningConfig: OptionsObject = {
  ...snackbarConfig,
  variant: "warning",
};

// SWR configs

export const swrConfigObject: SWRConfiguration = {
  fetcher,
  revalidateIfStale: false,
  revalidateOnFocus: true,
  revalidateOnReconnect: true,
};
